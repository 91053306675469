import { UserActions, UserActionTypes } from './user.actions';
import { User } from '@app/core/models';
import { createReducer, on } from '@ngrx/store';

export interface UserState {
	isLogin: boolean;
	error: any;
	payload: any;
	currentUser: User | null;
}

export const userInitialState: UserState = {
	isLogin: false,
	error: null,
	payload: null,
	currentUser: null,
};

export function userReducer(
	state: UserState = userInitialState,
	action: UserActions
): UserState {
	if (action.type.toString() === 'CLEAR_STORE') return userInitialState;

	switch (action.type) {
		case UserActionTypes.LogoutSuccess:
			return userInitialState;
		case UserActionTypes.LoginFail:
			return {
				...state,
				isLogin: false,
				payload: action.payload,
				error: action.payload,
				currentUser: null,
			};
		case UserActionTypes.LoginSuccess:
			return {
				...state,
				currentUser: action.payload,
				isLogin: true,
			};
		case UserActionTypes.UpdateUserEmail:
			return {
				...state,
				currentUser: {
					...state.currentUser,
					ContactEmail: action.payload,
				},
			};
		case UserActionTypes.UpdateUserSuccess:
		case UserActionTypes.SingleSignOnSuccess:
			return {
				...state,
				currentUser: action.payload,
				isLogin: true,
			};
		default:
			return state;
	}
}
