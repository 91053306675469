import { Component, OnInit } from '@angular/core';
import { routerTransition } from '@app/shared/utils/animations';
import { User } from '@app/core/models';

import { Store } from '@ngrx/store';
import * as fromUser from '@app/core/store/user';
import { Observable } from 'rxjs';

// primeNg
import { MessageService, ConfirmationService } from 'primeng/api';

@Component({
	selector: 'app-main-layout',
	templateUrl: './main-layout.component.html',
	styleUrls: ['./main-layout.component.scss'],
	animations: [routerTransition],
	providers: [MessageService, ConfirmationService],
})
export class MainLayoutComponent implements OnInit {
	currentUser$: Observable<User>;
	currentUser: User;

	scale: number = 14; // font 大小調整

	constructor(
		private store: Store<fromUser.UserState>,
		public messageService: MessageService
	) {
		this.currentUser$ = store.select(fromUser.getCurrentUser);
	}

	ngOnInit() {
		this.currentUser$.subscribe((v) => {
			this.currentUser = v;
		});
	}

	getState(outlet) {
		if (!outlet.activatedRoute) return;
		let ss = outlet.activatedRoute.snapshot;

		// return unique string that is used as state identifier in router animation
		return (
			outlet.activatedRouteData.state ||
			(ss.url.length
				? ss.url[0].path
				: ss.parent.url.length
				? ss.parent.url[0].path
				: null)
		);
	}

	backToTop(): void {
		$('html, body').animate({ scrollTop: 0 }, 300, 'swing');
	}

	incrementScale() {
		this.scale++;
		this.applyScale();
	}

	decrementScale() {
		this.scale--;
		this.applyScale();
	}

	applyScale() {
		document.documentElement.style.fontSize = this.scale + 'px';
		console.log('字型大小調整 this.scale:', this.scale);
		console.log(
			'字型大小調整 document.documentElement.style.fontSize:',
			document.documentElement.style
		);
	}
}
